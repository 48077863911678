import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import MainLayout from "../../components/MainLayout"
import { Helmet } from "react-helmet"
import {
  portfolio,
  portfolioNote,
  courses,
  course,
  courseThumbImage,
} from "../../styles/courses.module.css"

export default function Courses({ data }) {
  const coursesData = data.courses.nodes

  return (
    <MainLayout>
      <Helmet>
        <title>Linguolab Cursos</title>
        <meta
          name="description"
          content="Inscribete a nuestros cursos de inglés, francés y alemán, con múltiples horarios que se adaptan a tus necesidades"
        ></meta>
        <meta
          name="keywords"
          content="linguolab, aprender idiomas, aprender, idiomas, cursos, clases, plataforma, online, zoom, virtual, linguo, ingles, inglés, frances, francés, aleman, alemán"
        />
        <html lang="es" />
      </Helmet>
      <div className="page-header">
        <div className="page-header-titles">
          <h2>Cursos</h2>
        </div>
        <GatsbyImage
          image={data.titleImage.childImageSharp.gatsbyImageData}
          className="page-header-background"
          alt="thumb"
          objectFit="cover"
          objectPosition="24% 50%"
        />
      </div>
      <div className="layout">
        <div className={`page-content ${portfolio}`}>
          <div className={`layout grid ${courses}`}>
            {coursesData.map(courseData => (
              <Link
                to={`/courses/${courseData.frontmatter.slug}`}
                key={courseData.id}
                className={course}
              >
                <div>
                  <div className={courseThumbImage}>
                    <h1>{courseData.frontmatter.shortTitle}</h1>
                    <GatsbyImage
                      image={
                        courseData.frontmatter.thumb.childImageSharp
                          .gatsbyImageData
                      }
                      alt={courseData.frontmatter.slug}
                    />
                  </div>
                  <h3>{courseData.frontmatter.title}</h3>
                  <p>{courseData.frontmatter.hours}</p>
                </div>
              </Link>
            ))}
          </div>
          <p className={portfolioNote}>
            ¿Interesado en los cursos? <Link to="/contact">Contáctanos</Link>
          </p>
        </div>
      </div>
    </MainLayout>
  )
}

// export page query
export const query = graphql`
  query CoursesPage {
    courses: allMarkdownRemark(
      filter: { frontmatter: {type: {eq: "course"}} }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        frontmatter {
          slug
          title
          hours
          shortTitle
          thumb {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
    contact: site {
      siteMetadata {
        contactEmail
      }
    }
    titleImage: file(relativePath: { eq: "featured/title-2.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
